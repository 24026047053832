.consent-confirmation-thank-you__wrapper {
  box-shadow: 3px 3px 5px 3px #f1f1f1;
  border-radius: 10px;
  background-color: #faf8f8;
  padding: 4%;
  text-align: center;
  margin: auto;
}

.consent-confirmation-thank-you__checkmark {
  margin: auto;
}

.consent-confirmation-thank-you__header-text {
  padding-bottom: 2%;
}

.consent-confirmation-thank-you__checkmark-icon {
  width: 13%;
}

.consent-confirmation-thank-you__body-text {
  padding-top: 4%;
}

@media screen and (min-width: 600px) {
  .consent-confirmation-thank-you__wrapper {
    width: 50%;
    height: 75%;
    margin-top: 8rem;
  }
}

.consent-confirmation-logo {
  margin: 2rem;
  width: 26rem;
}

@media screen and (max-width: 1200px) {
  .consent-confirmation-logo {
    margin: 1rem;
    width: 18rem;  
  }
}
