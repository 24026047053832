/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  user-select: none;
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
  width: 90% !important;
  height: 100% !important;
  overflow: hidden;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 90% !important;
  height: 100% !important;
}

/*For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
}