.consent-form {
  font-family: sans-serif;
  text-align: center;
}

.container {
  max-width: 90%;
}

.form-logo {
  margin-top: 1rem;
  width: 125%;
}

.sticky-top {
  top: 1rem;
}

@media screen and (max-width: 767px) {
  .form-logo {
    width: 60%;
  }
}

.hidden {
  display: none;
}

.modal__header-left {
  text-align: left;
}

.modal__consent-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 5px;
  outline: none;
  padding: 1rem 0.8rem;
}

@media screen and (max-width: 600px) {
  .modal__consent-confirmation {
    width: 90%;
  }
}

.modal__overlay--consent-confirmation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 2;
}

.modal__header {
  text-align: center;
}

.modal__content-margin {
  text-align: center;
  padding: 4px 4px 4px 4px;
}

.modal__content {
  text-align: center;
}

.modal__hr-line {
  border-top: 1px solid rgb(211 211 211);
  opacity: 0.1;
}

.modal__timeout {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 10px;
  outline: none;
  padding: 20px;
  /*text-align: center;*/
}

.modal__overlay-timeout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}
